import { ReactNode } from 'react';
import { createPortal } from 'react-dom';

interface PortalProps {
  children: ReactNode;
  dom: Element;
}

export const Portal = (props: PortalProps) =>
  createPortal(props.children, props.dom);
